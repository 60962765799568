import { GoogleMap, InfoWindow, Marker } from "@react-google-maps/api";
import { useState, useCallback, useMemo, useEffect } from 'react';
import MapInfoCard from "./MapInfoCard";
import { useSelector, useDispatch } from "react-redux";
import { getMarkerIcon } from "utils/common.utils";
import GooglePlaceHolder from "assets/google-map-placeholder.png";
import { setMapObj } from "store/reducers/mapReducer";
import { useAddressPredictions } from "hooks/useAddressPredictions"
import { usePlacesService } from './PlaceServiceContext';

/* istanbul ignore next */
const Map = ({ markers, className, setSelectedLocation, handleEarlApptClick, handleClickDetails, handleClickViewAppt, selectedLocation, maptest, ...props }) => {
    const { isGoogleApiLoaded, loadTrigger, mapObj } = useSelector(state => state.map)
    const dispatch = useDispatch()

    const center = useMemo(() => ({ lat: 54.65107, lng: -100.347015 }), [])

    const mapRef = usePlacesService()

    const [map, setMap] = useState(null)
    const [activeMarker, setActiveMarker] = useState(null)

    const onMarkerClick = (marker, event) => {
        event?.preventDefault();
        setOpen(true)
        setActiveMarker(marker)
        setSelectedLocation(marker)
    }

    useEffect(() => {
        if (isGoogleApiLoaded && markers?.length > 0 && loadTrigger) {
            const bounds = new window.google.maps.LatLngBounds()

            markers?.map(marker => {
                bounds.extend(marker.position);
                return marker.id
            });
            map.fitBounds(bounds);
        }
    }, [map, markers, isGoogleApiLoaded])

    //const { updatePlacesService } = useAddressPredictions()

    const onLoad = useCallback(function callback(map) {
        setMap(map)
        // dispatch(setMapObj(!mapObj))

        //updatePlacesService(map)
        mapRef.current = map

    }, [])

    const [open, setOpen] = useState(false)

    const handleToggleClose = () => {
        setOpen(false)
    };

    useEffect(() => {
        if (selectedLocation) {
            setOpen(true)
            setActiveMarker(selectedLocation)
            setSelectedLocation(selectedLocation)
        } else {
            setOpen(false);
            setActiveMarker(null)
            setSelectedLocation(null)
        }
    }, [selectedLocation])

    return (
        <>
            {
                isGoogleApiLoaded ? <GoogleMap
                    mapContainerClassName={className}
                    center={center}
                    zoom={4}
                    onLoad={onLoad}
                    options={{streetViewControl:false}}

                >
                    {
                        markers?.map((marker, index) => {
                            return <Marker
                                key={index}
                                title={marker.siteName || ''}
                                position={marker.position}
                                onClick={() => onMarkerClick(marker)}
                                icon={{
                                    url: 'data:image/svg+xml;charset=UTF-8,' +
                                        encodeURIComponent(getMarkerIcon(index + 1)),
                                    scale: 0.5,
                                    optimized: false
                                }}
                            >
                                {
                                    open && activeMarker.id === marker.id &&
                                    <InfoWindow onCloseClick={handleToggleClose}>
                                        <MapInfoCard
                                            handleClickViewAppt={handleClickViewAppt}
                                            handleClickDetails={handleClickDetails}
                                            location={marker} id={index + 1}
                                            onCloseClick={handleToggleClose}
                                            handleEarlApptClick={handleEarlApptClick}
                                        />
                                    </InfoWindow>
                                }
                            </Marker>
                        })
                    }
                </GoogleMap> : <div className="map-container" style={{ width: '100%' }}><img src={GooglePlaceHolder} alt="Google Map" style={{ width: '100%' }}/></div>
            }
        </>

    );
}

export default Map;